/** System Files */
import React, { Component } from 'react';
import WebService from '../Utilities/webService';
import moment from 'moment';

/** Graph */
import PredLineGraph from '../Viz/Prediction/Graph';


class PredictiveProbability extends Component {

    state = {
        hide:this.props.hide,
        serials: this.props.serials,
        blink: false,
    };
    

    componentDidMount() {
        this.timer = setInterval(() =>  {
            this.getPredicitionProbabilities();
        },
        this.props.updateTimer);
    }

    componentWillUnmount() {
        this.timer = null; // here..
    }

    getPredicitionProbabilities() {
        const { serials } = this.state;
        let {datetime, faultType } = this.props;
        datetime = new Date(datetime);
        Object.keys(serials).map((serial) => {
                return WebService.getRequest(`/prediction/${serial}/?predictions_from=${datetime.toISOString()}`)
                    .then(result => {
                       if(typeof result !== 'undefined'){
                            if(typeof serials[serial].pred === 'undefined')
                                serials[serial].pred = [];
                            }
                            if(serials[serial].pred.length < result.length){
                                for(let i = 0; i<result.length; i++) {
                                    if(typeof serials[serial].predictions === 'undefined'){
                                        if (typeof result[i] !== 'undefined') {
                                            if(typeof result[i].data.predictions[0][faultType] !== 'undefined'){
                                                const date = moment.utc(result[i].timestamp).tz('Europe/London');
                                                serials[serial].pred.push(
                                                    { 
                                                        t: date,
                                                        y: result[i].data.predictions[0][faultType]
                                                    }
                                                );
                                            }
                                        }
                                    }
                                }
                                // // resort
                                // serials[serial].pred.sort((a,b) => {
                                    
                                // });

                            }
                           
                        })
                    .catch(error => 'NOT FOUND');
        });
        this.setState({ serials, blink:false});
    }

    render() {
        const { serials } = this.state;
        const { large } = this.props;
        return (
            <PredLineGraph data={serials} large={large} />
        );
    }
}

export default PredictiveProbability;

/** System Files */
import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import WebService from '../Utilities/webService';


/**
 * Default class to display on the main page
 */
class GoogleSignin extends Component {
    
    state = {
        loading: false
    }

    googleFail = (res) => {
        // console.log(res);
    }

    googleSuccess =  async (response) => {
        const loading = this.state;
        this.setState({
            loading: true
        });
        const res = await WebService.loginRequest('/userdetails/login',{'code':response.code});
        
        if(res.status !== 200){
            // TODO error out
        }
        else {
            const data = await res.json();
            localStorage.setItem('user', data.id);
            this.props.history.push('/dashboard'); 
        }
        
    }

    render() {
        const { loading } = this.state;
        return (
            <>
                <div className="grid-container">
                    <div className="grid-x align-center align-middle grid-margin-x " style={{'height':'80vh'}}>
                        <div className="small-11 medium-6 large-4 grid-y align-middle login-container ">
                            <div className="cell small-4 text-center">
                                <img src="https://images.squarespace-cdn.com/content/5e79e7a81e71b8352c1dcc34/1585067815024-1TK1Q6JOGVS9F3LBUNQV/Verv_Logo_Navy.png?content-type=image%2Fpng" alt="verv-logo" />
                                <p>DASHBOARDS</p>
                            </div>
                            <div className="small-9 text-center cell">
                                {!loading ? <GoogleLogin
                                    className="google-button"
                                    clientId="239316774257-j034868kes173o5vsef79ape253d26s0.apps.googleusercontent.com"
                                    buttonText="Login with Google"
                                    onSuccess={this.googleSuccess}
                                    onFailure={this.googleFail}
                                    responseType="code"
                                    accessType="offline"
                                    hostedDomain="greenrunning.com"
                                    cookiePolicy={'single_host_origin'}
                                /> : 
                                    <img src="/loading.gif" alt="loading" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GoogleSignin;

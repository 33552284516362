class Color {
   
    static  hashCode = (str) => { // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return 20 * hash;
    } 
    
    static intToRGB = (i) => {
        let c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
         let hex = "00000".substring(0, 6 - c.length) + c;
         return '#' + hex;
    }
}

export default Color;
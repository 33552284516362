import React, { Component } from 'react';
import Chart from 'chart.js';
import Color from '../../Utilities/Color';
import 'chartjs-plugin-annotation';


export default class CumLineGraph extends Component {
   
    constructor(props) {
        super(props)
        this.chartRef = React.createRef();
        this.datasets = [];
    }

    componentDidMount() {
        
        const { data } = this.props;

        Object.keys(data).map((value, index) => {
            const color = Color.intToRGB(Color.hashCode(data[value].nickname + data[value].serial));
            this.datasets.push({
                label: data[value].nickname,
                name: value,
                fill: false,
                backgroundColor: color,
                borderColor: color,
                showLine: true

            });
            return true;
        });

        this.buildChart();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        this.myChart.data.datasets.forEach((dataset) => {
            if(dataset.label !== 'Threshold'){
                const { name } = dataset;
                if(typeof data[name].cum !== 'undefined'){
                    dataset.data = data[name].cum;
                    dataset.data.sort((a,b) => {
                       
                        if(a.x < b.x){
                            return -1;
                        }
                        if(a.x > b.x){
                            return 1;
                        }
                        return 0;
                    });
                }
            }
            
        });
        if (prevProps.large !== this.props.large){
            this.myChart.resize();
        }
        this.myChart.update();  
    }
    
    buildChart = () => {

            this.myChart = new Chart(this.chartRef.current, {
                type: 'line',
                options: {
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                displayFormats: {
                                    minute: 'h:mm:ss a'
                                },
                            },
                            distribution: 'series',
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio:true,
                    animation: {
                        duration: 300,
                    },
                    annotation: {
                        annotations: [{
                          type: 'line',
                          mode: 'horizontal',
                          scaleID: 'y-axis-0',
                          value: parseFloat(0.0),
                          borderColor: 'rgb(255, 0, 0)',
                          borderWidth: 4,
                          label: {
                            enabled: true,
                            content: 'Threshold'
                          }
                        }]
                      }
                },
                data: {datasets: this.datasets},
            });
        }
    
    

    render() { 
        return (
            <canvas
                id='cum-graph'
                ref={this.chartRef}
            />
        );
    }
}



/** System Files */
import React, { Component } from 'react';
// import '../../Styles/components/checklist.css';
import Table from '../Components/Table';
import PredictiveProbability from '../Components/PredictiveProbability';
import CumulativeProbability from '../Components/CumulativeProbability';
import CompoundTimer from '../Components/Timer';
import WebService from '../Utilities/webService';
import DateTimePicker from 'react-datetime-picker';

/**
 * Default class to display on the main page
 */
class Dashboard extends Component {
    // dev.vc.verv.energy

    constructor(props){
        super(props);
        const d = new Date();
        d.setHours(d.getHours() - 2);
        this.state = {
            datetime:d,
            loadGraphs: false,
            toggleTable: false,
            togglePred: true,
            toggleCum: true,
            hideCumalative: false,
            hidePredictive: false,
            largeGraphs: false,
            fault_type: 'belt_tension'
        }
    }
    rootUrl = WebService.url;
    serials = {};
    updateTimer = 15000;
   
    componentDidMount() {
        WebService.getRequest(`/plugattributes/`)
            .then(result => {
                result = result.sort((a,b) => {
                    if(a.nickname < b.nickname){
                        return -1;
                    }
                    if(a.nickname > b.nickname){
                        return 1;
                    }
                    return 0;
                });
                for(const plug of result) {
                    this.serials[plug.serial] = {
                        "nickname": plug.nickname
                    }
                }
               this.setState({loadGraphs: true});
            })
    }

    onChange = (value) => {
        this.setState({datetime:value});
    }

    toggleTable = () => {
        const { toggleTable } = this.state;
        this.setState({ toggleTable: !toggleTable });
    }

    togglePred = () => {
        const { togglePred } = this.state;
        this.setState({ togglePred: !togglePred });
    }

    toggleCum = () => {
        const { toggleCum } = this.state;
        this.setState({ toggleCum: !toggleCum });
    }

    predButton = () => {
        const { togglePred } = this.state;
        return (<button className="verv-button primary-button wide float-right" onClick={this.togglePred}>{togglePred ? 'Hide Graph' : 'Predictive Graph'}</button>);
    }

    cumButton = () => {
        const { toggleCum } = this.state;
         return (<button className="verv-button primary-button wide float-right" onClick={this.toggleCum}>{toggleCum ? 'Hide Graph' : 'Cumulative Graph'}</button>);
    }


    largeGraphs = () => {
        const { largeGraphs } = this.state;
        this.setState({ largeGraphs: !largeGraphs });
    }

    faultChange = (eve) => {
        this.setState({
            fault_type: eve.target.value
        });
    }

    toggleUrl = (env) => {
        WebService.changeRoot(env);
    }

    render() {
        const { loadGraphs, toggleTable, togglePred, toggleCum, largeGraphs, datetime, fault_type } = this.state;
       
        return (
            <> 
                <div className="top-highlight"></div>
                <div  className="bg-wrapper">
                    <div className="grid-container">
                        <div className="top-bar">
                            <div className="top-bar-left">
                                <img src="https://images.squarespace-cdn.com/content/5e79e7a81e71b8352c1dcc34/1585067815024-1TK1Q6JOGVS9F3LBUNQV/Verv_Logo_Navy.png?content-type=image%2Fpng" alt="verv-logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="bg-wrapper-with-shadow">
                    <div  className="grid-container">
                        <div className={'grid-x grid-padding-x project-dashboard'}>
                            <h1  className="cell small-6">Dashboard Demo</h1>
                            <div className="cell small-5 grid-x align-right align-middle">
                                <button disabled className="button primary-button cell small-3" onClick={() => this.toggleUrl('env_1')}>ENV 1</button>
                                <button disabled className="button primary-button cell small-3" onClick={() => this.toggleUrl('env_2')}>ENV 2</button>
                                <button disabled className="button primary-button cell small-3" onClick={() => this.toggleUrl('env_3')}>ENV 3</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div  className="grid-container button-container">
                        <div className="grid-x grid-margin-x">
                        
                            <div className={ `cell grid-x align-middle small-7`}>
                                <button className="verv-button primary-button" onClick={this.toggleTable}>{toggleTable ? 'Hide Table' : 'Show Table'}</button>
                                { !togglePred ? this.predButton() : null }
                                { !toggleCum ? this.cumButton() : null }
                                <button className="verv-button primary-button" onClick={this.largeGraphs}>{largeGraphs ? 'Small Graphs' : 'Large Graphs'}</button>
                                <label>FAULT TYPE: &nbsp;
                                    <select onChange={(eve) => this.faultChange(eve)} value={fault_type}>
                                        <option value="belt_tension">Belt Tension</option>
                                        <option value="air_filter">Air Filter</option>
                                    </select>
                                </label>
                            </div>
                            <CompoundTimer />
                        </div>
                        Take Predictions from: <DateTimePicker clearIcon={null} onChange={this.onChange} value={datetime} />
                    </div>
                    <div className="grid-container">
                        <div className="grid-x grid-margin-x">
                           
                            <>
                                {loadGraphs ? 
                                    (<>
                                    <div className={largeGraphs ? 'small-12 medium-12 cell grid-x align-middle':'small-12 medium-6 cell grid-x align-middle'}>
                                        { togglePred ? <div className="shelf"><h3> Predictive Probability {this.predButton()} </h3></div>: null }
                                        { togglePred ? <PredictiveProbability faultType={fault_type} datetime={datetime} large={largeGraphs} hide={true} serials={this.serials} rootUrl={this.rootUrl} updateTimer={this.updateTimer} /> : null }
                                    </div>

                                    <div className={largeGraphs ? 'small-12 medium-12 cell grid-x align-middle':'small-12 medium-6 cell grid-x align-middle'}>
                                        { toggleCum ? <div className="shelf"><h3> Cumulative Probability {this.cumButton()} </h3></div>: null }
                                        { toggleCum ? <CumulativeProbability faultType={fault_type}  large={largeGraphs} hide={true} serials={this.serials} rootUrl={this.rootUrl} updateTimer={this.updateTimer} />: null }
                                    </div>
                                    </>) : null }
                            </>
                              
                            {toggleTable ?
                                <div className="small-12 cell grid-x align-middle" >
                                    <div className="shelf"><h3> Active Devices  </h3></div>
                                    <Table faultType={fault_type} serials={this.serials} rootUrl={this.rootUrl} updateTimer={this.updateTimer} />
                                </div> : null}

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Dashboard;

import Auth from './Auth';

class WebService {
    // default content-type
    static jsonHeaderConfig = {
        'Content-Type': 'application/json',
        'Access-Control-Request-Credentials': false,
        'Access-Control-Request-Origin': 'https://dashboards.vc.verv.energy',
        'Access-Control-Request-Methods': ['GET', 'POST', 'OPTIONS'],
        'Access-Control-Request-Headers': 'application/json, Authorization',
    };

    // TODO ENVIRONEMNT VARIABLES NEEDED
    static url = process.env.REACT_APP_ENV_1;
    static env_1 = process.env.REACT_APP_ENV_1;
    static env_2 = process.env.REACT_APP_ENV_2
    static env_3 = process.env.REACT_APP_ENV_3

    static changeRoot = (env) => {
        WebService.url = WebService[env];
    }

    /**
     * Get Request
     *
     * @param {String} path
     * @return {Promise}
     */
    static getRequest = async (path) => {

        let values = [];     
        // path is required
        if (path !== undefined) {
            const token = localStorage.getItem('user');
            const headers = WebService.jsonHeaderConfig;
            headers.Authorization = `Basic ${window.btoa(token)}`;
            try {
                const response = await fetch(WebService.url + path, {
                    method: 'GET',
                    headers,
                });
                if (!response.ok) {
                    // token expired
                    if (response.status === 401 || response.status === 403) {
                        Auth.logout();
                    }
                   
                    throw new Error(response.statusText);

                    
                } else {
                    const data = await response.json(); // or .json() or whatevers
                    return data;
                }
            } catch (err) {
                //
            }
        } else {
            throw new Error('Webservice: no path parameter');
        }
    };

    /**
     * Get Request
     *
     * @param {String} path
     * @param {Object} opts
     * @return {Promise}
     */
    static postRequest = async (path, opts) => {
        let values = [];
        if (Auth.hasToken()) {
            values = Auth.hasExpired().then(async (expired) => {
                if (!expired) {
                    if (path !== undefined) {
                        try {
                            const token = localStorage.getItem('user');
                            const headers = WebService.jsonHeaderConfig;
                            headers.Authorization = `Basic ${token}`;
                            const response = await fetch(WebService.url + path, {
                                method: 'POST',
                                headers,
                                body: JSON.stringify(opts)
                            });
                            return await response.json();
                            // process body
                        } catch (err) {
                            return 'Webservice: failed to fetch';
                        }
                    } else {
                        throw new Error('Webservice: no path parameter');
                    }
                }
                await Auth.logout();
                return false;
            });
        }
        return values;
    }


    /**
   * Get Request
   *
   * @param {String} path
   * @param {Object} opts
   * @return {Promise}
   */
    static loginRequest = async (path, opts) => {
        if (path !== undefined) {
            try {
                const token = localStorage.getItem('user');
                const headers = WebService.jsonHeaderConfig;
                const response = await fetch(WebService.url + path, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(opts)
                });
                return await response;
                // process body
            } catch (err) {
                return 'Webservice: failed to fetch';
            }
        } else {
            throw new Error('Webservice: no path parameter');
        }
    }
}
export default WebService;

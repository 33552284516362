class Auth {
    
    /**
     *
     * Remove user token
     *
     */
    static logout = (force = false) => {
        if (window.location.pathname !== '/signin' || force) {
            localStorage.removeItem('user');
        }
    }
}

export default Auth;

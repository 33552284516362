import React from 'react';
import Chart from 'chart.js';
import moment from 'moment'
import Color from '../../Utilities/Color';

export default class PredLineGraph extends React.Component {
    
    constructor(props) {
        super(props)
        this.chartRef = React.createRef();
        this.datasets = [];
    }
    
    componentDidMount() {
       
        const { data } = this.props;
        Object.keys(data).map((value, index) => {
            const color = Color.intToRGB(Color.hashCode(data[value].nickname + data[value].serial));
            this.datasets.push({
                label: data[value].nickname,
                name: value,
                fill: false,
                backgroundColor: color,
                borderColor: color,
                showLine: true

            });
            return true;
        });
        this.buildChart();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        this.myChart.data.datasets.forEach((dataset) => {
            const { name } = dataset;
            if(typeof data[name].pred !== 'undefined'){
                dataset.data = data[name].pred;
                dataset.data.sort((a,b) => {
                    if(a.t < b.t){
                        return -1;
                    }
                    if(a.t > b.t){
                        return 1;
                    }
                    return 0;
                });
            }
        })
        this.myChart.update();  
        if (prevProps.large !== this.props.large){
            this.myChart.resize();
        }
    }

    buildChart = () => {

        this.myChart = new Chart(this.chartRef.current, {
            type: 'line',
            options: {
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit:'minute',
                            displayFormats: {
                                minute: 'h:mm:ss a'
                            },
                        },
                        distribution: 'series',
                    }]
                },
                animation: {
                    duration: 300,
                }
            },
            data: {datasets: this.datasets},
        });
    }

    render() { 
      
        return (
            <canvas id='pred-graph' ref={this.chartRef} />
        );
    }
}

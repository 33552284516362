/** System Files */
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
/** Styles */
import './App.scss';
// import '../../Styles/components/checklist.css';
import Dashboard from '../Dashboard';
import GoogleSignin from '../GoogleSignin';

// import AuthContext from '../Auth/AuthContext';
/**
 * Default class to display on the main page
 */
class App extends Component {

    render() {
        return (
            <>
                <Router>
                    <div className="container">
                        <Switch>
                            <Route exact path="/signin" component={GoogleSignin} />
                            <Route render={props => (
                                localStorage.getItem('user')
                                    ? <Dashboard  />
                                    : <Redirect to={{ pathname: '/signin' }} />
                            )} />
                           
                        </Switch>
                    </div>
                </Router>
            </>
        );
    }View
}

export default App;

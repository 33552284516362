/** System Files */
import React, { Component } from 'react';
import WebService from '../Utilities/webService';


class Table extends Component {

    state = {
        serials: this.props.serials,
        blink: false
    };

    componentDidMount() {
        this.timer = setInterval(() => this.getItems(), this.props.updateTimer);
    }

    componentWillUnmount() {
        this.timer = null;
    }

    faultType = (prob) => {
        const { faultType } = this.props;
        
        if(faultType === 'air_filter') {
            if(prob > 0){
                return  'Fault';       
            }
            if(prob <= -10){
                return 'Normal'
            }
            if(prob > -10 && prob <= 0) {
                return 'Warning';
            }
           
        } else {
            if(prob > 2){
                return  'Fault';       
            }
            // belt tension
            if(prob > -2 && prob <= 2) {
                return 'Warning';
            }
            if(prob <= -2){
                return 'Normal';
            }
        }
    }

    getItems() {
        this.setState({blink:true});
        const { serials } = this.state; 
        const { faultType } = this.props;

        Object.keys(serials).map((serial) => {
            if(typeof serial !== 'undefined'){
                return WebService.getRequest(`/aggregation/${serial}/`)
                    .then(result => {
                        // filter for fault type
                        result = result.filter((value) => {
                            if(value.fault_type === faultType){
                                return value;
                            }
                        });
                        result = result.shift();
                        if (typeof result !== 'undefined') {
                            serials[serial].report= result.report;
                            this.setState({ serials, blink:false });
                        }
                    })
                    .catch(error => 'NOT FOUND');
                }
            });
        };

    render() {
        const { serials } = this.state;
       
        return (
            <table className="history">
                <thead>
                    <tr>
                        <th>Identifier</th>
                        <th>Type</th>
                        <th>Fault Status</th>
                        <th>Fault Probability</th>
                    </tr>

                </thead>
                <tbody>
                    {

                        Object.keys(serials).map((serial) => {
                           
                            return (
                                <tr key={serial}>
                                    <td>{serials[serial].nickname}</td>
                                    <td>{!Object.keys(serials[serial]).includes('report') ? '—' : `${serials[serial].report.fault_type}`}</td>
                                    <td>{!Object.keys(serials[serial]).includes('report') ? '—' :  this.faultType(serials[serial].report.cumulative_probability)}</td>
                                    <td>{!Object.keys(serials[serial]).includes('report') ? '—' : `${(Math.round(serials[serial].report.cumulative_probability * 100) / 100).toFixed(2)}` }</td>
                                </tr>
                            );
                        }) 
                    }
                </tbody>
            </table>
        );
    }
}

export default Table;

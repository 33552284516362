/** System Files */
import React, { Component } from 'react';
import WebService from '../Utilities/webService';
import moment from 'moment-timezone';
/** Graph */
import CumLineGraph from '../Viz/Cumulative/Graph';

class CumulativeProbability extends Component {

    state = {
        hide: this.props.hide,
        serials: this.props.serials,
        threshold: [],
        blink: false
    };

    componentDidMount() {
        this.timer = setInterval(() =>  {
            this.getCumulitiveProbabilities();
            this.buildThresholdData();
        }, this.props.updateTimer);
    }

    componentWillUnmount() {
        this.timer = null; // here...
    }

    getCumulitiveProbabilities() {
        const { serials } = this.state;
        const { faultType } = this.props;
        Object.keys(serials).map((serial) => {
                return WebService.getRequest(`/aggregation/${serial}/`)
                    .then(result => {
                       if(typeof result !== 'undefined'){
                            if(typeof serials[serial].cum === 'undefined'){
                                serials[serial].cum = [];
                            }
                            if(serials[serial].cum.length < result.length){
                                for(let i = 0; i<result.length; i++) {
                                    // console.log(result[i].fault_type)
                                        if(result[i].fault_type === faultType){
                                            const date = moment.utc(result[i].created_at).tz('Europe/London');
                                            serials[serial].cum.push(
                                                { 
                                                    x: date,
                                                    y: result[i].report.cumulative_probability
                                                }
                                            );
                                        }
                                    
                                }
                            
                            }
                        }
                    })
                    .catch(error => 'NOT FOUND');
        });
        this.setState({ serials });
                
    };

    buildThresholdData() {
        var threshold = this.state.threshold.concat(
            {
                x: new Date().toISOString(),
                y: 0
            }
        );
        this.setState({ threshold: threshold });
    }

    render() {
       
        const { serials, threshold } = this.state;
        const { large } = this.props;
        return (
            <CumLineGraph
                data={serials}
                threshold={threshold}
                labels="Time"
                large={large}
            />
        );
    }
}

export default CumulativeProbability;

/** System Files */
import React, { Component } from 'react';
import Timer from 'react-compound-timer';


class CompoundTimer extends Component {
    
    componentDidMount() {
       
    }
    render() {
        return (
            <div className="small-5 cell timer">
                <Timer  
                   
                    initialTime={0}
                    startImmediately={false}
                >
                    {({ start, resume, pause, stop, reset, timerState }) => (
                        <>
                            <div className="cell small-12 grid-x align-right align-middle">
                                <span className="timer-element"><Timer.Hours /><span>h</span></span>
                                <span className="timer-element"><Timer.Minutes /><span>m</span></span>
                                <span className="timer-element"><Timer.Seconds /><span>s</span></span>
                            </div>
                            <div className="cell small-12 grid-x align-right align-middle">
                                <button className="button primary" onClick={start}>Start</button>
                                <button className="button primary-info" onClick={pause}>Pause</button>
                                <button className="button primary-error" onClick={reset}>Reset</button>
                            </div>
                        </>
                    )}
                </Timer>
            </div>
        );
    }
}

export default CompoundTimer;